import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosInstance, AxiosResponse } from 'axios'

import { Paging, Result } from 'services/types'

import {
  ContactCanDeleteReponse,
  ContactQuery,
  ContactQueryResponse,
  ContactQueryResponseWithType,
  ContactPayload,
  GetContact,
  UpdateContactRequest,
} from './types'
import HttpClient from '../httpClient'
import { endpoints } from './endpoints'
import buildUrl from './../utils/buildUrl'

class ContactDriverImpl {
  public constructor(private readonly httpClient: AxiosInstance = HttpClient) {}

  public async update(
    contactId: string,
    contact: UpdateContactRequest,
  ): Promise<void> {
    return this.httpClient.put(`/contact/${contactId}`, contact)
  }

  public async query(
    query: Partial<ContactQuery> & Partial<Paging>,
  ): Promise<Result<ContactQueryResponse>> {
    const result = await this.httpClient.get<Result<ContactQueryResponse>>(
      '/contact',
      { params: query },
    )

    return result.data
  }

  public async comboboxContactQuery(
    query: Partial<ContactQuery> & Partial<Paging>,
  ): Promise<Result<ContactQueryResponseWithType>> {
    const result = await this.httpClient.get<
      Result<ContactQueryResponseWithType>
    >(`/contact/combobox/query`, { params: query })

    return result.data
  }

  public async canDelete(
    contactId: string,
  ): Promise<AxiosResponse<ContactCanDeleteReponse>> {
    const result = await this.httpClient.get<ContactCanDeleteReponse>(
      `/contact/${contactId}/canDelete`,
    )
    return result
  }

  public async delete(contactId: string): Promise<AxiosResponse<unknown>> {
    const result = await this.httpClient.delete(`/contact/${contactId}/delete`)
    return result
  }
}

export const ContactDriver = new ContactDriverImpl()

export const contactQuery = async (query: Partial<ContactQuery>) => {
  const result = await HttpClient.get<Result<ContactQueryResponse>>(
    endpoints.query,
    { params: query },
  )

  return result.data
}

interface GetContacts {
  payload: Partial<ContactQuery>
}

export const useGetContacts = ({ payload }: GetContacts) => {
  return useQuery({
    queryKey: ['contacts', payload],
    queryFn: async () => await contactQuery(payload),
    enabled: !!payload.partitionId,
    refetchOnMount: false,
  })
}

const createContact = async (payload: ContactPayload) => {
  return await HttpClient.post(endpoints.create, payload)
}

export const useCreateContact = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['create-contact'],
    mutationFn: async (payload: ContactPayload) => await createContact(payload),
    onSuccess: () => queryClient.removeQueries({ queryKey: ['contacts'] }),
  })
}

const editContact = async (contactId: string, payload: ContactPayload) => {
  return await HttpClient.put(
    buildUrl({ route: endpoints.edit, params: { contactId } }),
    payload,
  )
}

export const useEditContact = (contactId = '') => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['edit-contact'],
    mutationFn: async (payload: ContactPayload) => {
      if (contactId) {
        return await editContact(contactId, payload)
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get-contact', contactId] })
      queryClient.refetchQueries({ queryKey: ['contacts'] })
    },
  })
}

const getContact = async (contactId = '') => {
  const response = await HttpClient.get<GetContact>(
    buildUrl({ route: endpoints.get, params: { contactId } }),
  )

  return response.data
}

export const useGetContact = (contactId = '') => {
  return useQuery({
    queryKey: ['get-contact', contactId],
    queryFn: async () => await getContact(contactId),
    enabled: !!contactId,
  })
}

const removeContact = async (contactId = '', accountIds: string[] = []) => {
  const response = await HttpClient.patch<GetContact>(
    buildUrl({
      route: endpoints.remove,
      params: { contactId },
    }),
    {
      accountIds,
    },
  )

  return response.data
}

export const useRemoveContact = (contactId = '') => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['remove-contact', contactId],
    mutationFn: async (accountIds: string[]) => {
      if (!!contactId && !!accountIds.length)
        return await removeContact(contactId, accountIds)
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get-contact', contactId] })
    },
  })
}
